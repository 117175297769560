import useSWR from 'swr';

import { getAnnouncement, getAnnouncementReturn } from 'api';

export type useAnnouncementReturn = {
  isLoading: boolean;
  isError: boolean;
  data?: getAnnouncementReturn;
};

const useAnnouncement = (args: {
  initialData?: getAnnouncementReturn;
}): useAnnouncementReturn => {
  const { initialData } = args;

  const { data, error } = useSWR(
    {
      ...{ api: 'getAnnouncement' },
    },
    getAnnouncement,
    {
      fallbackData: initialData,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    data,
  };
};
export default useAnnouncement;
