import * as React from 'react';
import {
  Box,
  Rating as MuiRating,
  RatingProps as MuiRatingProps,
} from '@mui/material';

import { WishlistSolid } from 'assets/Icon';

type CustomStartProps = {
  size?: 'xxs' | 'medium' | 'large' | 'xl';
  color: string;
};

const CustomHeart = (props: CustomStartProps): JSX.Element => {
  const { size, color } = props;
  let iconSize;
  let boxSize;
  let paddingSize = '2px';
  switch (size) {
    case 'xl':
      iconSize = '28px';
      boxSize = '32px';
      break;
    case 'large':
      iconSize = '24px';
      boxSize = '28px';
      break;
    case 'medium':
      iconSize = '20px';
      boxSize = '24px';
      break;
    case 'xxs':
      iconSize = '10px';
      boxSize = '12px';
      paddingSize = '1px';
      break;
    default:
      iconSize = '20px';
      boxSize = '24px';
  }

  return (
    <Box
      width={boxSize}
      height={boxSize}
      display="block"
      padding={paddingSize}
      color={color}
      component="span"
      sx={{ fontSize: '1em', lineHeight: 0 }}
    >
      <WishlistSolid width={iconSize} height={iconSize} />
    </Box>
  );
};

export type RatingHeartProps = Omit<CustomStartProps, 'color'> &
  Omit<MuiRatingProps, 'size'> & {
    color?: 'red' | 'green';
  };

const RatingHeart = (props: RatingHeartProps): JSX.Element => {
  const { size = 'medium', color = 'red', ...muiProps } = props;

  return (
    <MuiRating
      {...muiProps}
      emptyIcon={
        <CustomHeart
          color={color === 'red' ? '#C9694D40' : '#43524430'}
          size={size}
        />
      }
      icon={
        <CustomHeart
          color={color === 'red' ? '#C9694D' : '#435244'}
          size={size}
        />
      }
    />
  );
};
export default RatingHeart;
