import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export type indexPromoData = {
  longBanner: string;
  cropLongBanner: string;
  squareBanner: string;
  cropSquareBanner: string;
  name: string;
  linkType: 'External' | 'Internal';
  linkPath: string;
  openLinkInNewWindows?: boolean;
};
export type getIndexPromoBannerReturn = null | indexPromoData[];

const getIndexPromoBanner =
  async (): CaffePromise<getIndexPromoBannerReturn> => {
    return caffe.request({
      method: 'GET',
      url: '/api/method/caff.e.get_promo_banner',
    });
  };
export default getIndexPromoBanner;
