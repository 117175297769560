import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

import { convertHtmlImgToCdnImg } from 'utils/image';

type DangerouslyDivHtmlProps = {
  html: string;
  className?: string;
  sx?: SxProps;
};
const DangerouslyDivHtml = (props: DangerouslyDivHtmlProps): JSX.Element => {
  const { html, className, sx } = props;
  const wrapHtml = (_html: string) => ({ __html: _html });

  const parsedHtml = convertHtmlImgToCdnImg(html);

  // allow iframe from youtube
  DOMPurify.addHook(
    'uponSanitizeElement',
    (
      node: Element,
      data: {
        tagName: string;
        allowedTags: { [key: string]: boolean };
      },
    ): Element | undefined => {
      if (data.tagName === 'iframe') {
        const src = node.getAttribute('src') || '';
        if (!src.startsWith('https://www.youtube.com/embed/')) {
          return node.parentNode?.removeChild(node);
        }
      }
      return undefined;
    },
  );
  const cleanHtml = DOMPurify.sanitize(parsedHtml, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: [
      'allow',
      'allowfullscreen',
      'frameborder',
      'scrolling',
      'target',
    ],
  });

  return (
    <Box
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={wrapHtml(cleanHtml)}
      sx={sx}
    />
  );
};
export default DangerouslyDivHtml;
