import useSWR from 'swr';

import { getIndexPromoBanner, getIndexPromoBannerReturn } from 'api';

export type useIndexPromoBannerReturn = {
  isLoading: boolean;
  isError: boolean;
  data?: getIndexPromoBannerReturn;
};

const useIndexPromoBanner = (args: {
  initialData?: getIndexPromoBannerReturn;
}): useIndexPromoBannerReturn => {
  const { initialData } = args;

  const { data, error } = useSWR(
    {
      ...{ api: 'getIndexPromoBanner' },
    },
    getIndexPromoBanner,
    {
      fallbackData: initialData,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    data,
  };
};
export default useIndexPromoBanner;
