import * as React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

import { RoastLevelFire } from 'assets/Icon';
import { makeClasses, joinSx } from 'styles';
import { Product } from 'types/product';

const useStyles = makeClasses({
  flavorRoast: {
    marginTop: 1.5,
    fontWeight: 'fontWeightLight',
    fontSize: '0.9rem',
    color: 'secondary.main',
  },
  customSliderLabel: {
    marginTop: '-10px',
    paddingBottom: '2rem',
    lineHeight: 1,
    position: 'relative',
    color: 'secondary.main',
  },
  customSliderLabelSkeleton: {
    paddingBottom: '2.3rem',
  },
  roastSliderSkeleton: {
    height: '2px',
    marginTop: '10px',
    marginBottom: '13px',
  },
});

export type CoffeeRoastLevelProps = {
  roastLevel: Product['roast'];
  size: 'xs' | 'md';
};
export const CoffeeRoastLevel = (props: CoffeeRoastLevelProps): JSX.Element => {
  const { roastLevel, size } = props;

  const sizeDict = {
    xs: {
      icon: {
        width: '16px',
        height: '16px',
        marginRight: '0.3rem',
        transform: 'translateY(1px)',
      },
      fontSize: '0.8rem',
      lightLineHeight: '1px',
      boldLineHeight: '1.5px',
    },
    md: {
      icon: {
        width: '20px',
        height: '20px',
        marginRight: '0.5rem',
        transform: 'translateY(1px)',
      },
      fontSize: '1rem',
      lightLineHeight: '2px',
      boldLineHeight: '3px',
    },
  };

  const levelSxDict = {
    'Light': {
      position: 0,
      transform: 'translate(0, -50%)',
    },
    'Light-Medium': {
      position: 25,
      transform: 'translate(-25%, -50%)',
    },
    'Medium': {
      position: 50,
      transform: 'translate(-50%, -50%)',
    },
    'Medium-Dark': {
      position: 75,
      transform: 'translate(-75%, -50%)',
    },
    'Dark': {
      position: 100,
      transform: 'translate(-100%, -50%)',
    },
  };

  return (
    <Box
      sx={{
        height: '20px',
        marginTop: '20px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            height: sizeDict[size].lightLineHeight,
            transform: 'translateY(-25%)',
            backgroundColor: '#B2B2B4',
            borderRadius: '1px',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: `${100 - levelSxDict[roastLevel].position}%`,
            height: sizeDict[size].boldLineHeight,
            transform: 'translateY(-25%)',
            backgroundColor: '#435244',
            borderRadius: '1.5px',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: `${levelSxDict[roastLevel].position}%`,
            width: 'max-content',
            transform: levelSxDict[roastLevel].transform,
            backgroundColor: 'white',
            padding: '0 0.4rem',
            color: '#435244',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            fontSize: sizeDict[size].fontSize,
          }}
        >
          <Box component="span" sx={sizeDict[size].icon}>
            <RoastLevelFire
              width={sizeDict[size].icon.width}
              height={sizeDict[size].icon.height}
            />
          </Box>
          <Box component="span">{roastLevel}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export type CoffeeRoastProps = {
  roastLevel: Product['roast'];
};
export default function CoffeeRoast(props: CoffeeRoastProps): JSX.Element {
  const classes = useStyles;
  const { roastLevel } = props;

  return (
    <>
      <Typography sx={classes.flavorRoast}>Roast</Typography>
      <CoffeeRoastLevel roastLevel={roastLevel} size="md" />
    </>
  );
}

export const CoffeeRoastLevelSkeleton: React.VoidFunctionComponent = () => {
  const classes = useStyles;

  return (
    <>
      <Typography sx={classes.flavorRoast}>
        <Skeleton width="5em" />
      </Typography>
      <Skeleton variant="rectangular" sx={classes.roastSliderSkeleton} />
      <Box
        sx={joinSx([
          classes.customSliderLabel,
          classes.customSliderLabelSkeleton,
        ])}
      >
        <Typography>
          <Box
            component="span"
            sx={{
              transform: 'translateX(-50%)',
              position: 'absolute',
              left: '50%',
            }}
          >
            <Skeleton width="3em" />
          </Box>
        </Typography>
      </Box>
    </>
  );
};
