import React from 'react';
import { Box } from '@mui/material';

import { Product } from 'types/product';
import { makeClasses, joinSx } from 'styles';
import { getImgUrlReplaceBe } from 'utils/image';

const useStyles = makeClasses({
  cardMedia: {
    'borderRadius': '2px',
    // make card media square based on width
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
    'display': 'block',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'backgroundPosition': 'center',
  },
});

type productImageDisplayProps = {
  url: string;
  stockStatus: Product['stockStatus'];
  width?: number;
};
const ProductImageDisplay = (props: productImageDisplayProps): JSX.Element => {
  const { url, stockStatus, width = 760 } = props;
  const classes = useStyles;

  return (
    <Box
      sx={joinSx([
        classes.cardMedia,
        {
          position: 'relative',
          backgroundImage: `url("${getImgUrlReplaceBe({
            imgPath: url,
            encodeUri: true,
            transformation: `w-${width}`,
          })}")`,
        },
      ])}
    >
      {['Out of Stock', 'Sold Out'].includes(stockStatus) ? (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '75%',
              left: 0,
              right: 0,
              bottom: '85%',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              transform: 'translate(0, -50%)',
              height: '2rem',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '75%',
              left: 0,
              right: 0,
              transform: 'translate(0, -50%)',
              color: '#707070',
              textAlign: 'center',
            }}
          >
            {stockStatus.toUpperCase()}
          </Box>
        </>
      ) : null}
    </Box>
  );
};
export default ProductImageDisplay;
