import * as React from 'react';
import { Box } from '@mui/material';

import { Announcement as AnnouncementIcon } from 'assets/Icon';
import { useAnnouncement } from 'composables';
import { DangerouslyDivHtml } from 'components/shared';

const PromoNoti = (): JSX.Element | null => {
  const { data } = useAnnouncement({});

  if (typeof data === 'string') {
    return (
      <Box
        sx={{
          backgroundColor: '#C9694D',
          color: 'white',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          zIndex: 90,
          padding: '0.5rem 1rem',
          opacity: 0.9,
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box display="flex" flexGrow={2} />
          <Box sx={{ paddingRight: '0.75rem', paddingLeft: 0 }} display="flex" flexGrow={0}>
            <AnnouncementIcon width="1.5rem" height="1.5rem" />
          </Box>
          <Box display="flex" justifyContent="center" flexDirection="column" flexGrow={0}>
            <Box sx={{ lineHeight: '1.2' }}>
              <DangerouslyDivHtml html={data} />
            </Box>
          </Box>
          <Box display="flex" flexGrow={2} />
        </Box>
      </Box>
    );
  }
  return null;
};
export default PromoNoti;
