import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export type getAnnouncementReturn = string | null;

const getAnnouncement = async (): CaffePromise<getAnnouncementReturn> => {
  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.get_announcement',
  });
};
export default getAnnouncement;
