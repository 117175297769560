import { Caffe, CaffePromise } from 'apiclient';

const caffe = new Caffe();

export interface getPageInfoProps {
  page: string;
}

export interface getPageInfoReturn {
  title: string;
  metaDescription: string;
}

const getPageInfo = async (props: getPageInfoProps): CaffePromise<getPageInfoReturn> => {
  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.query.page_info',
    params: props,
  });
};
export default getPageInfo;
