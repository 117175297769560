import { SxProps } from '@mui/system';
import merge from 'lodash/merge';

import { theme } from '../Theme';

type joinSxProps = (SxProps<typeof theme> | false | undefined | 0)[];
const joinSx = (sxList: joinSxProps): SxProps => {
  const joinedSx = {};

  merge(joinedSx, ...sxList.filter((sx) => Boolean(sx) !== false));

  return joinedSx;
};
export default joinSx;
